import {trackPromise} from "react-promise-tracker";

const axios = require('axios').default;
// 1 for prod, 2 for dev
const version = 1;

export async function listInstitutions() {
    return trackPromise(axios.get(`https://us-central1-hap-prod-315720.cloudfunctions.net/list_institutions?version=${version}`)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response
        }));
}

export async function getHapUserName(user_short_code) {
    return trackPromise(axios.post(`https://us-central1-hap-prod-315720.cloudfunctions.net/get_user_name?version=${version}`, {
        key: "evbZ5yZ4ynq0aJjnv3nM0Q3HdgvVzHmz",
        user_short_code: user_short_code
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response
        }));
}

export async function registerSupporter(phone_no) {
    return trackPromise(axios.post(`https://us-central1-hap-prod-315720.cloudfunctions.net/register_supporter?version=${version}`, {
        phone_no: phone_no
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response
        }));
}

export async function verifySupporter(user_short_code, phone_no, verification_code) {
    return trackPromise(axios.post(`https://us-central1-hap-prod-315720.cloudfunctions.net/verify_supporter?version=${version}`, {
        user_short_code: user_short_code,
        phone_no: phone_no,
        verification_code: verification_code
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response
        }));
}

export async function storeSupporter(user_short_code, peer_first_name, peer_last_name, peer_id) {
    return trackPromise(axios.post(`https://us-central1-hap-prod-315720.cloudfunctions.net/store_supporter?version=${version}`, {
        user_short_code: user_short_code,
        peer_first_name: peer_first_name,
        peer_last_name: peer_last_name,
        peer_id: peer_id,
        supporter_status: "ACCEPTED"
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error.response
        }));
}
