import React from "react";
import {Button, Form} from "semantic-ui-react";
import myala_logo from "./assets/myala_logo.png";
import verified from "./assets/verified.png";
import {getHapUserName, registerSupporter, storeSupporter, verifySupporter} from "./serviceWrapper";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import {isValidPhoneNumber} from 'react-phone-number-input'
import {Loader} from "./Loader";

const INCORRECT_URL_MSG = "This URL looks incorrect. Please verify the link with the person who sent it to you."

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_fn: "",
            user_ln: "",
            phone_number: "",
            first_name: "",
            last_name: "",
            verification_code: "",
            otp_sent: false,
            otp_verified: false,
            user_short_code: "",
            peer_id: "",
            phone_error: "",
            otp_error: "",
            fn_error: "",
            ln_error: "",
            phoneSubmitted: false,
            otpSubmitted: false,
            terms_accepted: false,
            isFormSubmitted: false,
            errorMessage: "",
            isURLError: false,
            isLoading: false,
            androidUrl: 'https://play.google.com/store/apps/details?id=net.flourishlabs.hap',
            iOSUrl: 'https://apps.apple.com/in/app/h%C3%A5p/id1577317140',
            selfSupporterError: false,
            existingSupporterError: false
        };
    }

    componentDidMount = async () => {
        let user_short_code = window.location.pathname.split('/')[1];
        if (user_short_code === undefined || user_short_code === "") {
            this.setState({errorMessage: INCORRECT_URL_MSG, isURLError: true});
            return;
        }
        this.setState({user_short_code: user_short_code});
        // console.log(user_short_code);
        this.setState({isLoading: true});
        let res = await getHapUserName(user_short_code);
        this.setState({isLoading: false});
        if (res === undefined) {
            this.setState({errorMessage: INCORRECT_URL_MSG, isURLError: true});
            return;
        } else if (res.status === "Failed") {
            this.setState({errorMessage: INCORRECT_URL_MSG, isURLError: true});
            return;
        }
        this.setState({user_fn: res.data.first_name, user_ln: res.data.last_name});
    };

    handleChange = (event) => {
        this.setState({[event.target.id]: event.target.value});
    };

    handleTerms = (event) => {
        if (event.target.checked) {
            this.setState({terms_error: ""})
        }
        this.setState({terms_accepted: event.target.checked});
    };

    handlePhoneNoChange = (event) => {
        let {phoneSubmitted} = this.state;
        this.setState({phone_number: event})
        if (phoneSubmitted && event && !event.isEmpty) {
            let isValid = isValidPhoneNumber(event)
            if (!isValid) {
                this.setState({phone_error: "Please provide a valid phone number."})
            } else {
                this.setState({phone_error: ""})
            }
        }
    };

    sendOtp = async () => {
        let {phone_number} = this.state;
        console.log(phone_number);
        let isValid = isValidPhoneNumber(phone_number)

        if (!isValid) {
            this.setState({phone_error: "Please provide a valid phone number.", phoneSubmitted: true})
        } else {
            this.setState({isLoading: true});
            await registerSupporter(phone_number);
            this.setState({phone_error: "", otp_sent: true, phoneSubmitted: true, isLoading: false});
        }
    };

    verifyOtp = async () => {
        let {user_short_code, phone_number, verification_code} = this.state;
        if (!verification_code || verification_code.isEmpty) {
            this.setState({otp_error: "Verification code cannot be empty.", otpSubmitted: true})
        }
        this.setState({isLoading: true});
        let res = await verifySupporter(user_short_code, phone_number, verification_code);
        this.setState({isLoading: false});
        let peer_id = res.data.peer_id;
        console.log("res: ", res.data, peer_id);

        if (peer_id === "-1") {
            this.setState({otp_verified: false, otp_error: "Verification code is incorrect."});
        } else {
            if (res.data.peer_first_name && res.data.peer_last_name) {
                this.setState({
                    otp_verified: true,
                    peer_id: peer_id,
                    first_name: res.data.peer_first_name,
                    last_name: res.data.peer_last_name
                });
            } else {
                this.setState({otp_verified: true, peer_id: peer_id});
            }
        }
    };

    handleSubmit = async () => {
        let {user_short_code, first_name, last_name, peer_id, terms_accepted} = this.state;
        console.log(user_short_code, first_name, last_name, peer_id);
        let err_flag = false;
        if (!first_name || first_name.isEmpty) {
            this.setState({fn_error: "First name cannot be empty."});
            err_flag = true;
        }
        if (!last_name || last_name.isEmpty) {
            this.setState({ln_error: "Last name cannot be empty."});
            err_flag = true;
        }
        if (!terms_accepted) {
            this.setState({terms_error: "Please accept the terms of service and privacy policy."});
            err_flag = true;
        }
        if (err_flag) {
            return;
        }
        this.setState({fn_error: "", ln_error: "", terms_error: "", isLoading: true});
        let res = await storeSupporter(user_short_code, first_name, last_name, peer_id);
        if (res.data.message === "User can't be his own peer!") {
            this.setState({selfSupporterError: true})
        }
        if (res.data.message === "User-Peer Pair already exists!") {
            this.setState({existingSupporterError: true})
        }
        this.setState({isFormSubmitted: true, isLoading: false});
    };

    render() {
        let {
            user_fn,
            user_ln,
            first_name,
            last_name,
            phone_number,
            otp_sent,
            otp_verified,
            phone_error,
            otp_error,
            fn_error,
            ln_error,
            terms_error,
            isFormSubmitted,
            isURLError,
            errorMessage,
            isLoading,
            androidUrl,
            iOSUrl,
            existingSupporterError
        } = this.state;
        return (
            <div>
                {isLoading && <Loader/>}
                {isURLError && <div className="d-flex mx-auto px-4 pt-2">
                    <div className="d-flex flex-column mx-auto pt-4 my-auto">
                        <img alt="" src={myala_logo} width={150}/>
                        <span className="mt-2">{errorMessage}</span>
                    </div>
                </div>
                }
                {!isURLError && <div className="d-flex flex-column w-100 my-5">
                    {!isFormSubmitted && <div className="d-flex flex-column mx-auto">
                        <img alt="" src={myala_logo} width={150}/>
                        <div
                            className="d-flex mt-4 font-weight-bold"
                            style={{fontSize: "20px"}}
                        >
                            What is myala?
                        </div>
                        <div className="mt-3" style={{fontSize: "15px"}}>
                            myala is a self-tracking app for the mind <br/>that fosters human connection.
                        </div>
                        <div
                            className="d-flex mt-4 font-weight-bold"
                            style={{fontSize: "20px"}}
                        >
                            What am I signing up for as a <br/>supporter?
                        </div>
                        <div className="mt-3" style={{fontSize: "15px"}}>
                            As a peer supporter, you will receive 
                            <br/> text messages from the myala phone number
                            <br/> whenever your friend activates their
                            <br/> their myala signal. By activating their myala
                            <br/> signal, this is a signal to you from the person
                            <br/> you're supporting that they need someone
                            <br/> to talk to. At this point, you would reach out
                            <br/> to the person you're supporting.
                        </div>
                        <div className="mt-4 font-weight-bold" style={{fontSize: "20px"}}>
                            Yes! I want to be part of {user_fn.trimEnd()}'s <br/> support team.
                        </div>
                        <Form className="mt-2">
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column" style={{width: "90%"}}>
                                    <PhoneInput
                                        disabled={otp_verified}
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="US"
                                        value={phone_number}
                                        onChange={this.handlePhoneNoChange}
                                    />
                                    {<span
                                        disabled={isLoading}
                                        style={{
                                            marginLeft: '20px',
                                            color: 'red',
                                            fontSize: '12px'
                                        }}>{phone_error}</span>}
                                    {otp_sent && !otp_verified && <span
                                        onClick={this.sendOtp}
                                        className="d-flex mx-auto"
                                        style={{textDecoration: "underline", cursor: "pointer", fontSize: "15px"}}
                                    >
                                  Resend code
                                </span>}
                                    {!otp_sent && <Button
                                        disabled={isLoading}
                                        className="d-flex mt-2 mx-auto btn btn-primary"
                                        style={{background: "black", color: "white", border: "none"}}
                                        onClick={this.sendOtp}>
                                        Send code
                                    </Button>}
                                </div>
                                {otp_verified && <div className="my-auto ml-2">
                                    <img alt="" src={verified} width={20}/>
                                </div>}
                            </div>
                            {otp_sent && !otp_verified && <div className="d-flex flex-column" style={{width: "90%"}}>
                                <Form.Input
                                    label="Verification code:"
                                    className="mt-3"
                                    type="text"
                                    id="verification_code"
                                    onChange={this.handleChange}
                                />
                                {<span
                                    style={{marginLeft: '0px', color: 'red', fontSize: '12px'}}>{otp_error}</span>}
                                <Button
                                    disabled={isLoading}
                                    className="d-flex mt-2 mx-auto btn btn-primary"
                                    style={{background: "black", color: "white", border: "none"}}
                                    onClick={this.verifyOtp}
                                >
                                    Verify code
                                </Button>
                            </div>}
                            {otp_sent && otp_verified && <div className="mt-4">
                                <div style={{width: "90%"}}>
                                    <Form.Input
                                        value={first_name}
                                        label="Your first name:"
                                        type="text"
                                        id="first_name"
                                        onChange={this.handleChange}
                                    />
                                    {<span
                                        style={{marginLeft: '20px', color: 'red', fontSize: '12px'}}>{fn_error}</span>}
                                </div>
                                <div className="" style={{width: "90%"}}>
                                    <Form.Input
                                        value={last_name}
                                        label="Your last name:"
                                        type="text"
                                        id="last_name"
                                        onChange={this.handleChange}
                                    />
                                    {<span
                                        style={{marginLeft: '20px', color: 'red', fontSize: '12px'}}>{ln_error}</span>}
                                </div>
                            </div>}
                        </Form>

                        {
                            otp_verified && <div>
                                                        <div className="d-flex"
                             style={{marginTop: otp_sent && otp_verified ? "0px" : "16px"}}>
                            <a href="https://projecthap.com/terms/" target="_blank"
                            style={{textDecoration: "underline", cursor: "pointer"}} rel="noreferrer"
                            >
                                Terms of service
                            </a>
                            <li style={{textDecoration: "underline", cursor: "pointer", paddingInlineStart: 16}}>
                                    <a href="https://projecthap.com/privacy/" target="_blank" rel="noreferrer">
                                     Privacy policy
                                    </a>
                            </li>
                        </div>
                        <div className="d-flex mt-2 align-items-center">
                            <input
                                className="d-flex mr-2 mb-auto mt-2"
                                type="checkbox"
                                onChange={this.handleTerms}
                                style={{height: 20, width: 20}}
                            />
                             <div>
                            <div>
                            I have read and agree to the myala terms <br/> of service and privacy policy above.
                            </div>
                            {<span
                            style={{marginLeft: '0px', color: 'red', fontSize: '12px'}}>{terms_error}</span>}
                             </div>
                        </div>
                                    <Button
                                        disabled={!otp_sent || !otp_verified || isLoading}
                                        className="d-flex mt-2 mx-auto btn btn-primary"
                                        style={{background: "black", color: "white", border: "none"}}
                                        onClick={this.handleSubmit}>
                                        Join team
                                    </Button>
                            </div>
                        }

                        </div>
                        }
                    {isFormSubmitted && <div className="px-4 pt-2">
                        <img alt="" src={myala_logo} width={150}/>
                        <div className="d-flex flex-column mx-auto pt-4 my-auto">
                            Thank you for agreeing to be a supporter for {user_fn} {user_ln}.
                            {!existingSupporterError &&
                                <div>We have just sent you a text from the myala signal number +1 833 427 2327. Please add
                                    this
                                    number to your contacts, so when we send a text you will recognise it as a myala
                                    signal.<br/><br/>
                                    The text will include 'This is a myala signal', your friend's name and a personal
                                    message from them
                                    </div>}
                            <br/>
                            If you want to try myala for yourself, you can download it from:

                            <div class="line">
                            <a href={iOSUrl}
                                   target="_blank" rel="noreferrer">
                                    <img style={{padding: "4px"}}
                                         height="75" width="180"
                                         alt='Get it on Google Play'
                                         src='https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg'/></a>
                            <a href={androidUrl}
                                   target="_blank" rel="noreferrer">
                                    <img height="75" width="180"
                                         alt='Get it on Google Play'
                                         src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                            </div>

                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        );
    }
}

export default App;
