import React from "react";

export function Loader() {
    return (
        <div className="loader-overlay">
            <div>
                <div className="progress bg-transparent">
                    <div className="indeterminate"/>
                </div>
            </div>
        </div>
    );
}
